<template>
    <div class="home-news">
        <div class="home-news-left-tab">
            <el-tabs tab-position="left" v-model="activeName" @tab-click="handleClick">
                <template v-for="item in newsGroupList">
                    <el-tab-pane :label="item.name" :name="item.id+''"></el-tab-pane>
                </template>
            </el-tabs>
        </div>
        <div class="home-news-right-content">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>新闻列表</el-breadcrumb-item>
            </el-breadcrumb>

            <div>
                <template v-for="item in news_list">
                    <NewsCard :news-item="item" @click="onClickNews"></NewsCard>
                </template>
            </div>
        </div>

        <el-drawer :title="news_title" :visible.sync="isShowDetail" direction="ltr" size="800px" custom-class="news-detail-draw">
            <div class="news-content">
                <div v-html="news_content">

                </div>
            </div>
        </el-drawer>

    </div>

</template>

<script>
    import {PostApi} from '@/api'
    import NewsCard from './news/NewsCard'

    export default {
        name: "News",
        components: {
            NewsCard,
        },
        data() {
            return {
                activeGroupId: 1,
                activeName: "1",
                newsGroupList: [],


                news_list: [],
                tableQuery: {
                    page: 1,
                    limit: 20,
                    sort: '-id',
                    queryParam: {
                        group_id: {op: '==', name: 'group_id', value: ''},
                    }
                },

                isShowDetail: false,
                news_title: "",
                news_content: "",
            }
        },
        created() {
            let query = this.$route.query;
            if (query.g) {
                this.activeGroupId = parseInt(query.g);
                this.activeName = this.activeGroupId + '';
            }
            if (query.n) {
                let news_id = parseInt(query.n);
                let item = {
                    id: news_id,
                    click_num: 0,
                }
                this.loadNewsDetail(item);
            }

            this.loadNewsGroupData();
        },
        methods: {
            handleClick() {
                this.activeGroupId = parseInt(this.activeName);
                this.onLoadNewsList();
            },

            /**
             * 加载新闻分类
             */
            loadNewsGroupData() {
                let payload = {
                    group_type: "1,2", // 公司动态，行业新闻
                }
                PostApi.newsGroupGetList(payload).then((data) => {
                    if (data.c === 0) {
                        this.newsGroupList = data.data;
                        if (this.newsGroupList && this.newsGroupList.length > 0) {
                            if (!this.activeGroupId) {
                                this.activeName = this.newsGroupList[0].id + '';
                                this.activeGroupId = parseInt(this.activeName);
                            }
                            this.onLoadNewsList();
                        }
                    }
                }).catch((err) => {
                });
            },

            /**
             * 加载分类下的新闻列表
             */
            onLoadNewsList: function () {
                if (this.activeGroupId < 0) {
                    return;
                }
                // 获取新闻列表
                this.tableQuery.queryParam.group_id.value = this.activeGroupId
                PostApi.newsGetList({"data": JSON.stringify(this.tableQuery)}).then((resp_data) => {
                        if (resp_data.c !== 0) {
                            return
                        }
                        this.news_list = resp_data.data
                        console.log(this.news_list);
                    }
                ).catch((err) => {

                })
            },

            onClickNews(item) {
                this.loadNewsDetail(item);
            },

            loadNewsDetail(item) {
                let payload = {
                    id: item.id,
                };
                PostApi.getNewsDetail(payload).then(response => {
                    if (response.c === 0) {
                        this.isShowDetail = true;
                        this.news_title = response.data.title;
                        this.news_content = response.data.content;
                        item.click_num = response.data.click_num;
                    }
                });
            },
        }
    }
</script>

<style lang="scss">
    .home-news {
        width: 750px;
        margin: 20px auto auto;
        display: flex;
    }

    .home-news-left-tab {
        .el-tabs__header {
            width: 150px;
        }
    }

    .home-news-right-content {
        padding: 0 20px;
        width: 732px;
        min-height: 640px;
        overflow-y: auto;
    }

    .news-detail-draw {
        .el-drawer__header {
            font-size: 16px;
            color: black;
            text-align: center;
        }

        .el-drawer__body {
            background-color: #f5f7fa;
            border-top: #DCDFE6 1px solid;
            margin-bottom: 20px;
            overflow-y: auto;
        }

        .news-content {
            background-color: #fff;
            width: 600px;
            height: 100%;
            margin: auto;
            padding: 30px 20px;

        }
    }
</style>