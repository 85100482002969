<template>
	<div class="news-card" @click="onClickItem">
		<div class="news-card-image">
			<img class="news-card-img" :src="newsItem.news_img"/>
		</div>
		<div class="news-card-info">
			<div class="news-card-title">
				<span>{{newsItem.title}}</span>
			</div>
			<div class="news-card-desc">
				<div class="news-sign-info">
					<div>
						<span class="news-sign-text">{{newsItem.create_time ? new Date(newsItem.create_time).format('yyyy-MM-dd') : ""}}</span>
					</div>
					<div>
						<i class="el-icon-view"></i>
						<span class="news-view-num-text">{{newsItem.click_num}}</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>

	export default {
		props: {
			newsItem: {
				type: Object,
				default: {
					click_num: 0,
					content: "",
					create_time: "2020-03-09 22:31:57",
					desc: "汽车连接器的种类可以从电气功能 、安装部位、连接结构、形状规格、尺寸规格、形状规格、工作频率这六个方面进行分类",
					id: 3,
					is_delete: "n",
					news_img: "/upload_storage/image/37cb315a13da3ba1c7204ab5e26371e9.jpeg",
					title: "汽车连接器的种类以及分类方式",
				}
			},
			click: {
				type: Function
			}
		},
		methods: {
			onClickItem: function () {
				this.$emit("click", this.newsItem)
				// let query = {
				// 	groupId: this.newsItem.groupId,
				// 	id: this.newsItem.id
				// }
				// this.$router.push({path: '/news/detail', query: query})
			}
		}
	}
</script>

<style>

	.news-card {
		background-color: #ffffff;
		display: flex;
		justify-content: flex-start;
		align-content: flex-start;
		padding: 15px;
		border-bottom: 1px solid #f1f1f1;
	}

	.news-card-image {
		margin-right: 10px;
	}

	.news-card-img {
		width: 160px;
		height: 100px;
	}

	.news-card-info {
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		width: 100%
	}

	.news-card-desc {
		display: flex;
		justify-content: space-between;
		flex-direction: row;
	}

	.news-card-title {
		font-size: 16px;
	}

	.news-sign-info {
		display: flex;
		justify-content: space-between;
		flex-direction: row;
		align-content: center;
		/*align-items: center;*/
		width: 100%;
	}

	.news-sign-text {
		font-size: 12px;
		color: #aeaeae;
	}

	.news-view-num-text {
		font-size: 12px;
		margin-left: 5px;
		color: #aeaeae;
		vertical-align: text-top;
	}

</style>
